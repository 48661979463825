import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from '../../components/Loading/Loading';
import useMount from '../../lib/useMount';

export const ClosePopup = () => {
  const getHashParameters = () => {
    const hashParams: any = {};
    window.location.hash.substr(1).split('&').forEach((item) => {
      const [key, value] = item.split('=');
      hashParams[key] = decodeURIComponent(value);
    });
    return hashParams;
  };

  useMount(() => {
    microsoftTeams.initialize();

    // The Azure implicit grant flow injects the result into the window.location.hash object. Parse it to find the results.
    const hashParams = getHashParameters();
    // If consent has been successfully granted, the Graph access token should be present as a field in the dictionary.
    if (hashParams.access_token) {
      // Notify the showConsentDialogue function in Tab.js that authorization succeeded. The success callback should fire.
      microsoftTeams.authentication.notifySuccess(hashParams.access_token);
    } else {
      microsoftTeams.authentication.notifyFailure(`Consent failed: ${hashParams}`);
    }
  });

  return (
    <Loading>
      <span>Thanks! Sending you back to Teams…</span>
    </Loading>
  );
};

export default ClosePopup;
