import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Tab from './pages/Tab/Tab';
import ConsentPopup from './pages/ConsentPopup/ConsentPopup';
import ClosePopup from './pages/ClosePopup/ClosePopup';

import './App.scss';

function App() {
  return (
    <Routes>
      <Route path="/tabs" key="tabs" element={<Tab />} />
      <Route path="/auth-start" key="auth-start" element={<ConsentPopup />} />
      <Route path="/auth-end" key="auth-end" element={<ClosePopup />} />
    </Routes>
  );
}

export default App;
