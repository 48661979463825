import React, { FunctionComponent, useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { Context } from '@microsoft/teams-js';
import ReactMarkdown from 'react-markdown';
import Loading from '../../components/Loading/Loading';
import useMount from '../../lib/useMount';
import NoAccount from '../../components/NoAccount/NoAccount';
import { Errors } from '../../lang/Errors';
import css from './Tab.module.scss';

enum Failures {
  BLOCKED = 'FailedToOpenWindow',
  CANCELLED = 'CancelledByUser',
}

export const Tab: FunctionComponent = () => {
  const [graphAccessToken, setGraphAccessToken] = useState('');
  const [loginCode, setLoginCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [noAccount, setNoAccount] = useState(false);

  const [context, setContext] = useState<Context | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);

  // Generic error handler ( avoids having to do async fetch in try/catch block )
  const unhandledFetchError = () => {
    setError(Errors.GENERAL);
  };

  // Callback function for a successful authorization
  const consentSuccess = (result: string) => {
    // Save the Graph access token in state
    setGraphAccessToken(result);
  };

  const consentFailure = (reason: string) => {
    switch (reason) {
      case Failures.CANCELLED:
        setError(Errors.POPUP_CANCELLED);
        break;
      case Failures.BLOCKED:
        setError(Errors.POPUP_BLOCKED);
        break;
      default:
        setError(Errors.GENERAL);
    }
  };

  // Show a popup dialogue prompting the user to consent to the required API permissions. This opens ConsentPopup.js.
  // Learn more: https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad#initiate-authentication-flow
  const showConsentDialog = () => {
    microsoftTeams.authentication.authenticate({
      url: `${window.location.origin}/auth-start`,
      width: 600,
      height: 535,
      successCallback: (result) => { consentSuccess(result ?? ''); },
      failureCallback: (reason) => { consentFailure(reason ?? ''); },
    });
  };

  // Exchange the SSO access token for a Graph access token
  // Learn more: https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-on-behalf-of-flow
  const exchangeTeamsClientTokenForGraphToken = async (token: string) => {
    const serverURL = `${process.env.REACT_APP_API_ENDPOINT}/login/teams/graph_token`;
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        auth_token: token,
      }),
    };

    const response = await fetch(serverURL, params)
      .catch(unhandledFetchError); // This calls getGraphAccessToken route in /api-server/app.js
    if (response) {
      if (response.status >= 400 && response.status < 500) {
        showConsentDialog(); // Proceed to show the consent dialogue.
      } else {
        const data = await response.json().catch(unhandledFetchError);
        if (!response.ok) {
          // Unknown error
          console.error(data);
          setError(Errors.GENERAL);
        } else {
          /**
           * Server side token exchange worked. Save the access_token to state, so that it can be picked up and used by
           * the componentDidMount lifecycle method.
           */
          setGraphAccessToken(data.auth_token);
        }
      }
    }
  };

  const ssoLoginSuccess = async (result: string) => {
    await exchangeTeamsClientTokenForGraphToken(result);
  };

  const ssoLoginFailure = () => {
    setError(Errors.GENERAL);
  };

  const startAuth = () => {
    /**
     * This logic basically checks to see if we were given a URL that we need to re-direct to. This means the user will
     * be coming from a card from the bots page. We are safe to assume we want to re-direct the user to somewhere
     * within our own application if this is the case.
     */
    if (
      context !== null
      && context.subEntityId
      && context.subEntityId.length > 0
    ) {
      setRedirect(context.subEntityId);
    }
    if (context !== null) {
      const authTokenRequestOptions = {
        successCallback: (result: string) => { ssoLoginSuccess(result); }, // The result variable is the SSO token.
        failureCallback: () => { ssoLoginFailure(); },
      };
      microsoftTeams.authentication.getAuthToken(authTokenRequestOptions);
    }
  };

  useMount(() => {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.initialize();

    microsoftTeams.getContext((c) => {
      setContext(c);
    });
  });

  useEffect(startAuth, [context]);

  useEffect(() => {
    const exchangeGraphToken = async () => {
      if (graphAccessToken && graphAccessToken.length > 0) {
        await exchangeGraphTokenForRungwayLoginCode();
      }
    };
    exchangeGraphToken();
  }, [graphAccessToken]);

  /**
   * If we have a login code, we want to re-direct to the main web app with the login code in the URL. We also want
   * to make sure we pass another param so that we know its a person coming from teams.
   */
  useEffect(() => {
    if (loginCode && loginCode.length > 0) {
      /**
       * If a redirect was set (i.e they came from a bots message)
       */
      if (redirect) {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/login?code=${loginCode}&teams=true&redirect=${redirect}`;
      } else {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/login?code=${loginCode}&teams=true`;
      }
    }
  }, [loginCode]);

  const exchangeGraphTokenForRungwayLoginCode = async () => {
    const graphPhotoEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/login/teams/sso`;
    const graphRequestParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        auth_token: graphAccessToken,
      }),
    };

    const response = await fetch(graphPhotoEndpoint, graphRequestParams).catch(unhandledFetchError);
    if (response) {
      if (!response.ok) {
        console.log('Could not login');
        console.error(response);
        setError(Errors.GENERAL);
        setNoAccount(true);
      } else {
        const responseJson = await response.json().catch(unhandledFetchError);
        console.log('Got Login Code');
        setLoginCode(responseJson.code);
      }
    }
  };

  if (loginCode) {
    return (
      <Loading>
        <span className={css.message}>Logging into your Rungway now</span>
      </Loading>
    );
  }

  if (error) {
    return (
      <div>
        {
          noAccount
            ? (
              <NoAccount />
            )
            : (
              <Loading>
                <ReactMarkdown className={css.message} data-qa="auth-message">{error}</ReactMarkdown>
                <button className={css.button} type="button" onClick={startAuth}>Retry</button>
              </Loading>
            )
        }
      </div>
    );
  }

  return (
    <div>
      <Loading>
        <span className={css.message} data-qa="auth-message">Authorising your Teams account</span>
      </Loading>
    </div>
  );
};

export default Tab;
