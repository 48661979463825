import React from 'react';
import {
  Card, BorderRadius, Button, Size,
} from '@rungway/rungway-connect';
import css from './NoAccount.module.scss';
import login from './login.png';

export const NoAccount = () => {
  const navigateLogin = () => {
    window.location.href = 'https://go.rungway.com/invite';
  };

  return (
    <div className={css.noAccount}>
      <Card.Base
        className={css.wrapper}
        padding={Size.LG}
        borderRadius={BorderRadius.SM}
      >
        <img src={login} alt="Rungway" />
        <p className={css.message}>
          Your organisation may not have Rungway on Microsoft Teams yet. Please contact your Teams administrator for information.
        </p>
        <Button
          className={css.button}
          text="Request an invite"
          onClick={() => navigateLogin()}
        />
      </Card.Base>

    </div>
  );
};

export default NoAccount;
