import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import Loading from '../../components/Loading/Loading';
import useMount from '../../lib/useMount';

export const ConsentPopup = () => {
  useMount(() => {
    /**
         * Use crypto web api to generate a nonce
         */
    const int16 = new Int16Array(16);
    crypto.getRandomValues(int16);

    // Initialize the Microsoft Teams SDK
    microsoftTeams.initialize();

    // Get the user context in order to extract the tenant ID
    microsoftTeams.getContext((context: microsoftTeams.Context) => {
      const tenant = context.tid; // Tenant ID of the logged in user

      // Client ID of the Azure AD app registration ( may be from different tenant for multitenant apps)
      const client_id = process.env.REACT_APP_AZURE_AD_CLIENT_ID;

      // Form a query for the Azure implicit grant authorization flow
      // https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
      let queryParams: any = {
        tenant: `${tenant}`,
        client_id: `${client_id}`,
        response_type: 'token', // token_id in other samples is only needed if using open ID
        scope: 'https://graph.microsoft.com/User.Read',
        redirect_uri: `${window.location.origin}/auth-end`,
        nonce: int16.toString(),
      };

      const url = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?`;
      queryParams = new URLSearchParams(queryParams).toString();
      const authorizeEndpoint = url + queryParams;

      // Redirect to the Azure authorization endpoint. When that flow completes, the user will be directed to auth-end
      // Go to ClosePopup.js
      window.location.assign(authorizeEndpoint);
    });
  });

  return (
    <Loading>
      <span>Redirecting you to the permissions.</span>
    </Loading>
  );
};

export default ConsentPopup;
