import React, { FunctionComponent } from 'react';
import css from './Loading.module.scss';
import loading from './loading.gif';

export const Loading: FunctionComponent = ({
  children,
}) => (
  <div className={css.loading}>
    <img src={loading} alt="loading" />
    { children }
  </div>
);

export default Loading;
